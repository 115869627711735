export const ApiUrl = 'https://dllmzqxapi.kjcxchina.com';


//判断客户端
export const IsPC = () => {
    const userAgentInfo = navigator.userAgent;
    const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];

    let flag = true;

    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break
        }
    }

    return flag
};
// 验证手机号
export const IsRegular = (data) => {
    return /^1[3456789]\d{9}$/.test(data)
}
// 验证身份证号
export const VerificationCard = (data) => {
    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(data)
}
//判断是否为微信浏览器
export const IsWeiXin = () => {
    const ua = navigator.userAgent.toLowerCase();
    const isWeixin = ua.indexOf('micromessenger') != -1;
    
    if (isWeixin) {
        return true;
    }else{
        return false;
    }
}

//手机号/邮箱脱敏
export const Desensitization = (data) => {
    if(data.includes('@')){
        let email = data.split('@');
        if(email[0].length>4){
            return email[0].substring(0, email[0].length-3) + '*****' + email[1];
        }else{
            return '*****' + email[1];
        }
    }else{
        return data.slice(0, 3) + "****" + data.slice(data.length - 4);
    }
}
