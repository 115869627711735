import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import PCHome from '@/views/pc/home.vue'
import MHome from '@/views/m/home.vue'

import { IsPC as IsPC } from '@/utils/util'

Vue.use(VueRouter)

// if(window.location.host == 'zzcl.kjcxchina.com'){
//   indexUrl = '../views/pc/index.vue'
// }
// if(window.location.host=='jt.zzcl.kjcxchina.com'){
//   indexUrl = '../views/pc/group_index.vue'
//   // @/views/pc/group_index.vue
// }

const routes_pc = [
  {
    path: '*',
    redirect: '/'
  },

  {
    path: '/',
    name: 'PCHome',
    component: PCHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('@/views/pc/index.vue'),
        meta:{
          topState:true,
          title:'首页'
        }
      },{
        path: '/lmgjList',
        name: 'lmgjList',
        component: () => import('@/views/pc/lmgjList.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/lmgjDetail/:id',
        name: 'lmgjDetail',
        component: () => import('@/views/pc/lmgjDetail.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/cjwtList',
        name: 'cjwtList',
        component: () => import('@/views/pc/cjwtList.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/ytzqList',
        name: 'ytzqList',
        component: () => import('@/views/pc/ytzqList.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/ytzqDetail/:id',
        name: 'ytzqDetail',
        component: () => import('@/views/pc/ytzqDetail.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/newsList',
        name: 'newsList',
        component: () => import('@/views/pc/newsList.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/newsDetail/:id',
        name: 'newsDetail',
        component: () => import('@/views/pc/newsDetail.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/form/companyForm',
        name: 'form/companyForm',
        component: () => import('@/views/pc/form/companyForm.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/form/demandForm',
        name: 'form/demandForm',
        component: () => import('@/views/pc/form/demandForm.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/form/modelWorkerForm',
        name: 'form/modelWorkerForm',
        component: () => import('@/views/pc/form/modelWorkerForm.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/form/unionForm',
        name: 'form/unionForm',
        component: () => import('@/views/pc/form/unionForm.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/login',
        name: 'login',
        component: () => import('@/views/pc/login.vue'),
        meta:{
          topState:true
        }
      },{
        path: '/userCont/userInfo',
        name: 'userCont/index',
        component: () => import('@/views/pc/userCont/index.vue'),
        meta:{
          topState:true
        },
        children:[
          {
            path: '/userCont/userInfo',
            name: 'userCont/userInfo',
            component: () => import('@/views/pc/userCont/userInfo.vue'),
            meta:{
              topState:true
            }
          },{
            path: '/userCont/myYuyue',
            name: 'userCont/myYuyue',
            component: () => import('@/views/pc/userCont/myYuyue.vue'),
            meta:{
              topState:true
            }
          },{
            path: '/userCont/password',
            name: 'userCont/password',
            component: () => import('@/views/pc/userCont/password.vue'),
            meta:{
              topState:true
            }
          },{
            path: '/userCont/feedback',
            name: 'userCont/feedback',
            component: () => import('@/views/pc/userCont/feedback.vue'),
            meta:{
              topState:true
            }
          },{
            path: '/userCont/myQuestion',
            name: 'userCont/myQuestion',
            component: () => import('@/views/pc/userCont/myQuestion.vue'),
            meta:{
              topState:true
            }
          }
        ]
      },{
        path:'/userIndex/userInfo',
        name:'userIndex/index',
        component:()=>import('@/views/pc/userIndex/index.vue'),
        meta:{
          topState:true
        },
        children:[
          {
            path: '/userIndex/userInfo',
            name: 'userIndex/userInfo',
            component: () => import('@/views/pc/userIndex/userInfo.vue'),
            meta:{
              topState:true
            }
          },{
            path: '/userIndex/discuss',
            name: 'userIndex/discuss',
            component: () => import('@/views/pc/userIndex/discuss.vue'),
            meta:{
              topState:true
            }
          },{
            path: '/userIndex/myYuyue',
            name: 'userIndex/myYuyue',
            component: () => import('@/views/pc/userIndex/myYuyue.vue'),
            meta:{
              topState:true
            }
          },{
            path: '/userIndex/password',
            name: 'userIndex/password',
            component: () => import('@/views/pc/userIndex/password.vue'),
            meta:{
              topState:true
            }
          }
          
        ]
      }
    ]
  }
]
const routes_m = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'mHome',
    component: MHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('@/views/m/index.vue'),
        meta:{
        }
      }
    ]
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: IsPC() ? routes_pc : routes_m
  // routes: routes_pc 
})

export default router
